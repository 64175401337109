<template>

<com-content :inner="true" class="form">

	<app-input-text :disabled="sent" v-model="model.name" label="Name" placeholder="Enter your name" />
	<app-input-text :disabled="sent" v-model="model.email" label="E-mail" placeholder="Enter your e-mail address" />
	<app-input-text :disabled="sent" v-model="model.message" label="Message" :autogrow="true" placeholder="Enter your message" />

	<app-button v-if="!sent" text="Submit" :disabled="isValid" theme="yellow" v-on:click="onSubmitClick" :loading="loading" />
	<app-button v-if="sent" text="Sent" theme="green" />
	
</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./../common/Content')

	},

	data: function() {

		return {
			loading: false,
			sent: false,
			model: {
				name: '',
				email: '',
				message: ''
			}
		}

	},

	validations: {
		model: {}
	},

	methods: {

		onSubmitClick: function() {

			this.loading = true
			
			this.$api.post('contact', this.model).then(function() {

				this.sent = true

			}.bind(this), function() {

				this.$notify({
					message: ['Sorry, your message could not be sent. Please try again.'],
					type: 'error'
				})

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.form {
	padding: 20px 10px;
	width: 100%;
}

.form >>> .input {
	width: 100%!important;
}

</style>